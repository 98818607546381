import { useFormContext } from "react-hook-form"
import { blank, classNames, lang, t } from '@/components/common/Helpers'
import LaravelErrorOutput from './LaravelErrorOutput'
import Tooltip from "../Tooltip"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

export default function Input({ label, name, required, placeholder, defaultValue, type = "text", disabled = false, autocomplete, readOnly = false, pattern = null, onReset, min, max, rules, submitErrors = null, maxLength = null, tooltip = null, onChange = null, onBlur = null, hideMaxLength = null }) {

    const { register, getValues, formState: { errors }, watch } = useFormContext()

    const getNestedErrors = (name, errors) => {
        return name.split('.').reduce((current, key) => current && current[key], errors)
    }

    const error = getNestedErrors(name, errors)

    const inputDefaultClasses = 'block w-full ' + (disabled ? 'bg-gray-100 text-gray-400' : 'bg-gray-50') + ' border-0 border-transparent border-b sm:text-md'
    const inputCleanClasses = 'focus:border-blue-600 focus:ring-0'
    const inputErrorClasses = 'pr-10 border-red border-red-500 text-red-900 placeholder-red-300 focus:outline-none focus:ring-0 focus:border-red-500'

    const wrapperDefaultClasses = 'mt-1 border-b'
    const wrapperCleanClasses = 'border-gray-300 focus-within:border-blue-600'
    const wrapperErrorClasses = 'border-red-500 focus-within:border-red-500'

    const wrapperClassName = classNames(wrapperDefaultClasses + ' ' + (error ? wrapperErrorClasses : wrapperCleanClasses), type === 'hidden' ? 'hidden' : '')
    const inputClassName = inputDefaultClasses + ' ' + (error ? inputErrorClasses : inputCleanClasses)

    const locale = lang

    if (type === 'range') {
        watch(name)
    }

    let validations = {}
    if (rules) {
        const ruleArray = rules.split("|")
        const patterns = []

        ruleArray.forEach((rule) => {
            switch (rule.trim()) {
                case "uuid":
                    patterns.push({
                        regex: /^[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/,
                        message: "Invalid UUID format. Ex. xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                    })
                    break
                case "number":
                    patterns.push({
                        regex: /^[0-9]+$/,
                        message: "Invalid number format.",
                    })
                    break
                case "money":
                    patterns.push({
                        regex: /^[0-9]+(\.[0-9]{1,2})?$/,
                        message: "Please enter a valid amount (e.g., 10 or 10.99).",
                    })
                    break
                case "phone":
                    patterns.push({
                        regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: "Invalid phone number format. Ex. (555) 555-5555",
                    })
                    break
                case "email":
                    patterns.push({
                        regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email format. Ex. your-email@your-domain.com",
                    })
                    break
                case "password":
                    patterns.push({
                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                        message: "Password must be a minimum of 8 characters, and include at least one uppercase letter, one lowercase letter and one number"
                    })
                    break
            }
        })

        if (patterns.length > 0) {
            validations.validate = (value) => {
                if (!required && !value) {
                    return true
                }

                for (const pattern of patterns) {
                    if (!pattern.regex.test(value)) {
                        return pattern.message
                    }
                }
                return true
            }
        }
    }

    if (maxLength) {
        watch(name)
    }

    return (
        <>
            {label &&
                <div className="flex relative items-center">
                    <label htmlFor={name} className={classNames("block mr-2 text-sm font-medium", disabled ? 'text-gray-500' : 'text-gray-700')}>
                        {label}
                    </label>
                    {tooltip &&
                        <>
                            <div className="has-tooltip ">
                                <InformationCircleIcon className="w-5 h-5 mr-2 text-blue-400" />
                                <Tooltip title={tooltip} />
                            </div>
                        </>
                    }
                    {type == 'range' && (
                        <p className='text-sm text-gray-500'>{getValues()[name]}</p>
                    )}
                    {onReset &&
                        <label className="block text-sm font-medium text-blue-500 cursor-pointer" onClick={onReset}>Reset</label>
                    }
                </div>
            }
            <div className={classNames(wrapperClassName)}>
                <input
                    {...register(name, { required, ...validations })}
                    type={type}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    className={classNames(inputClassName, disabled ? 'text-gray-500' : '')}
                    disabled={disabled}
                    autoComplete={autocomplete}
                    readOnly={readOnly}
                    pattern={pattern}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                    {...onChange ? onChange = { onChange } : {}}
                    onBlur={onBlur}
                />
            </div>
            {!hideMaxLength && maxLength ? (
                <div className="flex justify-between text-xs text-gray-400">
                    <div>Max characters: {maxLength}</div>
                    <div>{getValues(name)?.length || 0} / {maxLength}</div>
                </div>
            ) : ''}
            {
                error &&
                <p className="mt-2 text-sm text-red-600">
                    {error?.type === 'required' ? (
                        <>
                            {blank(label) || (typeof locale === 'string' && locale.slice(0, 2) !== 'en') ? (
                                <span>{t.trans('forms.required')}</span>
                            ) : (
                                <span>{label} is required</span>
                            )}
                        </>
                    ) : (
                        <>
                            {error.message}
                        </>
                    )}
                </p>
            }
            <LaravelErrorOutput name={name} submitErrors={submitErrors} />
        </>
    )
}
